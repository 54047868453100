import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';
import { reject } from 'q';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userSubject = new Subject<firebase.User>();
  private _loggedInPromise:Promise<boolean>;
  user$ = this._userSubject.asObservable();

  constructor(private afAuth: AngularFireAuth) {
    this._loggedInPromise = new Promise<boolean>((resolve,reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.
          
          resolve(true);
          this._userSubject.next(user);
        } else {
          // No user is signed in.
          reject(false);
          this._userSubject.next(null);
        }
      });

    });
  }

  isExistingUser(email:string):Promise<boolean>{
    var p = new Promise<boolean>((resolve,reject) =>{
      this.afAuth.auth.fetchSignInMethodsForEmail(email).then((methods)=>{
        if(methods.length > 0){
          resolve(true);
        }else{
          reject(false);
        }
      }).catch((error)=>{
        reject(false);
      });
    });
    return p;
  }

  signOut():Promise<void>{
    if(this.afAuth.auth.currentUser){
      return this.afAuth.auth.signOut();
    }
    return Promise.resolve();
  }

  signIn(email:string, password:string):Promise<firebase.auth.UserCredential>{
    var p = new Promise<firebase.auth.UserCredential>((resolve,reject) =>{
      this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(()=>{
        this.afAuth.auth.signInWithEmailAndPassword(email,password)
          .then((userCredential)=>{
            console.log('user signIn resolved');
            resolve(userCredential);
          })
          .catch((reason)=>{
            reject(reason);
          });
        });
    });
    return p;
  // return this.afAuth.auth.signInWithEmailAndPassword(email,password);
  }
  signUp(email:string, password:string):Promise<firebase.auth.UserCredential>{
    var p = new Promise<firebase.auth.UserCredential>((resolve,reject) =>{
      this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(()=>{
        this.afAuth.auth.createUserWithEmailAndPassword(email,password)
          .then((userCredential)=>{
            resolve(userCredential);
          })
          .catch((reason)=>{
            reject(reason);
          });
        });
    });
    return p;
  }

  isSignedIn():Promise<boolean>{
    return this._loggedInPromise;
  }
}
