import { Component, NgZone } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'pushtwentyfour';
  user: firebase.User;

  constructor(
    private _userService:UserService,
    private _router:Router,
    private zone: NgZone
  ){
    this._userService.user$.subscribe((user)=>{
      this.zone.run(() => this.user = user);
    });
  }

  signOut(){
    this._userService.signOut().then(()=>{
      this._router.navigateByUrl('/login');
    });
  }
}
