// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAlPMd_cqN3-zAK1rufEzkFEA4QAHxMJSY",
    authDomain: "push24-staging.firebaseapp.com",
    databaseURL: "https://push24-staging.firebaseio.com",
    projectId: "push24-staging",
    storageBucket: "",
    messagingSenderId: "571059483151",
    appId: "1:571059483151:web:854758150b463c69"
  }
};
